import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';

// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { ToastsContainer } from './modules/shared/toasts-container/toasts-container.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DatePipe } from '@angular/common'
import { ErrorDialogService } from './_metronic/shared/errors/error-dialog.service';
import { LoadingDialogService } from './_metronic/shared/loading/loading-dialog.service';
import { LoadingDialogComponent } from './_metronic/shared/loading/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from './_metronic/shared/errors/error-dialog/error-dialog.component';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, SPINNER, NgxUiLoaderConfig, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader'
import { EncryptDecryptAuthInterceptor } from './modules/portal/_http-interceptors/encrypt-decrypt-interceptor';

function appInitializer(authService: AuthService)
{
  return () =>
  {
    return new Promise((resolve) =>
    {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

const sharedComponents = [LoadingDialogComponent, ErrorDialogComponent];

// begin : Loader
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  blur: 0.5,
  bgsColor: '#OOACC1',
  bgsOpacity: 0.5,
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 100,
  bgsType: SPINNER.chasingDots,
  fgsColor: '#283583',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 60,
  fgsType: SPINNER.ballSpinClockwiseFadeRotating,
  pbColor: '#283583',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 2,
  text: '',
  textColor: '#283583',
  textPosition: POSITION.centerCenter,
  overlayColor: 'rgba(40, 40, 40, 0.2)',
  masterLoaderId: 'loader'
};
// end : Loader

@NgModule({
  declarations: [
    AppComponent,
    ToastsContainer
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),

    // begin : Loader
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule.forRoot({ showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId }),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId }),
    // end : Loader
  ],
  providers: [
    // {
    //   provide: 'BASE_URL',
    //   useFactory: getBaseUrl
    // }, 
    // {
    //   provide: ErrorHandler,
    //   //useClass: AppErrorHandler
    // }, 
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: EncryptDecryptAuthInterceptor,
    //   multi: true
    // },

    ErrorDialogService, 
    LoadingDialogService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function getBaseUrl()
{
  return document.getElementsByTagName('base')[0].href;
}