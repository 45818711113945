// GREEK
export const locale = {
  lang: 'gr',
  data: {
    TRANSLATOR: {
      SELECT: 'Επιλογή γλώσσας ',
    },
    MENU: {
      NEW: 'νέο ',
      ACTIONS: 'Ενέργιες ',
      CREATE_POST: 'Δημιουργία νέας ανάρτησης ',
      PAGES: 'Σελίδες ',
      FEATURES: 'Χαρακτηριστικά ',
      APPS: 'Εφαρμογές ',
      DASHBOARD: 'Αρχή ',
      CENTRALVIEW: 'Κεντρική Οθόνη',

      // Menu sections 
      CITY_HALL: 'PORTAL ',
      ACCOUNTING: 'Λογιστήριο ',
      PORTAL: 'Πυλη Δημοτών',
      CONFIGURATOR: "Διαμόρφωση ",

      PASSWORDS: "Διαχείριση Κωδικών",
      PASSWORDITEMS: "Κωδικοί",

      // Settings
      SETTINGS: 'Ρυθμίσεις ',
      COUNTRIES: 'Χώρες ',
      LANGUAGES: 'Γλώσσες ',
      DYNAMICREPORTS: "Δυναμικές Αναφορές ",
      TASKSCHEDULER: "Χρονοδιάγραμμα Eργασιών ",
      EMAILPROVIDERS: "Πάροχοι Email ",
      SMSPROVIDERS: "Πάροχοι Sms ",
      EVENTENGINEMESSAGES: "Μηνύματα ",
      EVENTENGINEMESSAGETYPES: "Τύποι Μηνυμάτων ",

      // System Security
      SECURITYSYSTEMS: "Σύστημα Ασφαλείας ",
      USERS: "Χειριστές ",
      ROLES: "Ρόλοι ",

      // Notifications
      NOTIFICATIONS: "Ειδοποιήσεις ",
      MESSAGES: "Μηνύματα ",

      // ERP
      ERP: "ERP ",
      CUSTOMERSVIEW: "Προβολή Πελατών ",
      SUPPLIERSVIEW: "Προβολή Προμηθευτών ",
      STOCKITEMSVIEW: "Προβολή Ειδών ",

      // CONFIGURATOR
      ANSWERS: "Απαντήσεις",
      QUESTIONS: "Ερωτήσεις ",
      QUESTIONGROUPS: "Ομάδες Ερωτήσεων ",
      QUESTIONNAIRES: "Ερωτηματολόγια ",
      WIZARD: "Διαμορφωτής - Παραγγελία ",
      PRODUCTCATEGORIES: "Κατηγορίες Προιόντων ",
      OFFERS: "Προσφορές ",
      ORDERS: "Παραγγελίες ",
      ANSWERGROUPS: "Ομάδες Απαντήσεων ",

      //Markting
      MARKETING: "Εμπορία / Marketing ",
      APPLICANTS: "Επαφές ",
      PROFESSIONS: "Επαγγέλματα ",
      REGIONALUNITS: "Νομοί ",
      SOURCES: "Πηγές ",

      // MY
      MY: "Χρεώσεις ",
      SPECIALTASKS: "Ειδικές Εργασίες ",
      REPORTS: "Εκτυπώσεις ",
      MYCUSTOMERS: "Πελάτες ",
      MYSUPPLIERS: "Προμηθευτές ",
      MYSELLERS: "Πωλητές ",
      MYCUSTOMERTRANSACTIONTYPES: "Τύποι Κίνησης Πελατών ",
      MYSUPPLIERTRANSACTIONTYPES: "Τύποι Κίνησης Προμηθευτών ",
      MYSTOCKITEMGROUPTRANSACTIONTYPES: "Τύποι Κίνησης Ομάδων Υλικών ",
      MYSTOCKITEMTRANSACTIONTYPES: "Τύποι Κίνησης Υλικών ",
      MYCUSTOMERGEOGRAPHICALAREAS: "Γεωγραφικές Περιοχές Πελατών ",
      MYCUSTOMERPAYMENTTYPES: "Τρόποι Πληρωμής Πελατών ",
      MYSUPPLIERPAYMENTTYPES: "Τρόποι Πληρωμής Προμηθευτών ",
      MYSTOCKITEMGROUPS: "Ομάδες Υλικών Αποθήκης ",
      MYCUSTOMERTRANSACTIONS: "Κινήσεις Πελατών ",
      MYSUPPLIERTRANSACTIONS: "Κινήσεις Προμηθευτών ",
      TRANSACTIONS: "Κινήσεις ",
      MASTERS: "Βασικά ",
      MYCOSTS: "Έξοδα ",
      MYSTOCKITEMGROUPTRANSACTIONS: "Κινήσεις Ομάδων (Υλικών) ",
      MYSTOCKITEMTRANSACTIONS: "Κινήσεις (Υλικών) ",
      MYBULKSTOCKITEMTRANSACTIONS: "Μαζικές Κινήσεις Υλικών Αποθήκης",
      MYREPORT1: "Αναφορά Αποτελεσμάτων ανά Ομάδα Ειδών ",
      MYREPORT2: "Αναφορά Τζίρου Ομαδων Ειδών ",
      MYREPORT3: "Αναφορά Τζίρου Πωλητών ",
      MYREPORT4: "Αναφορά Εισπράξεων ",
      IMPORTFROMERP: "Εισαγωγή από ERP",
      REBUILDADDERS: "Ανακατασκευή Αθροιστών ",
      MYREPORT5: "Αναφορά Υπολοίπων Πελατών",
      MYREPORT6: "Αναφορά Υπολοίπων Προμηθευτών",
      MYREPORT7: "Αναφορά Ενηλικίωσης Υπολοίπων Πελατών ",
      MYREPORT8: "Αναφορά Εξόδων ",
      COMPETITIONS: "Ανταγωνισμός ",
      GRADINGS: "Βαθμολογία ",
      MEETINGTYPES: "Τύποι Συναντήσεων ",
      MEETINGSTATUSES: "Καταστάσεις Συναντήσεων ",
      MYMEETINGS: "Συναντήσεις ",
      MYWAREHOUSES: "Αποθηκευτικοί Χώροι ",
      MYWAREHOUSE: "Αποθήκη ",
      MYREPORT9: "Αναφορά Τζίρου Πωλητών / Έτος ",
      MYREPORT10: "Αναφορά Τζίρου Γεωγραφικών Περιοχών / Έτος ",
      RESULTS: "Αποτελέσματα ",

      // KOFINI 
      KOFINIGR: "kofini.gr",
      MYACCOUNT: "Ο Λογαριασμός μου",
      MYCUSTOMERORDERS: "Οι Παραγγελίες μου",
      MYWISHLIST: "Τα Αγαπημένα ",
      MYSTOCKITEMS: "Αποθήκη (Υλικά) ",
      MYSTOCKITEMMEASUREMENTUNITS: "Μονάδες Μέτρησης",

      // EODA
      EODA: "ΕΟΔΑ ",
      MEMBERS: "Μέλη ",
      S101: "Σ101",
      FINTRANSACTIONS: "Οικονομικές Κινήσεις ",
      EODALEVELS: "Βαθμίδες ",
      EODASECTORS: "Τομείς ",
      EODADRIVINGLICENSES: "Άδειες Οδήγησης ",
      EODASPECIALITIES: "Ειδικότητες ",
      EODAEDUCATIONS: "Εκπαιδεύσεις ",
      EODAACTIONS: "Δράσεις ",
      EODASOCIALOFFERACTIONS: "Δράσεις Κοινωνικής Προσφοράς ",
      EODAENTERPRISES: "Επιχειρήσεις ",
      EODAPRACTICES: "Ασκήσεις ",
      EODAFIRSTAIDS: "Α' Βοήθειες ",
      EODAEDUCATIONGROUPS: "Ομάδες Εκπαιδεύσεων ",
      EODASCHOOLS: "Σχολεία/Πιστοποιήσεις ",
      EODAVACCINES: "Εμβόλια ",
      EODEQUIPMENT: "Εξοπλισμός ",
      EODADOCUMENTS: "Αρχεία Κειμένου ",
      EODAINCIDENTS: "Περιστατικά ",
      EODAGGPPACTIONS: "Δράσεις ΓΓΠΠ ",
      EODASHIFTGROUPS: "Ομάδες Κινητοποίησης ",
      EODASHIFTS: "Βάρδιες ",
      EODAEDUCATION: "Εκπαίδευση ",
      ASSIGNMENTOFQUESTIONNAIRES: "Ανάθεση Ερωτηματολογίων ",

      // EOD
      EOD: "ΕΟΔ ",
      EODDMRSERVERUSERS: "Χειριστές DMR ",
      BRANCHES: "Περιοχές ΕΟΔ ",

      // ATLS
      ATLS: "ATLS",

      // PROJECTS
      PROJECTS: "Έργα ",
    },
    AUTH: {
      GENERAL: {
        OR: 'ή',
        SUBMIT_BUTTON: 'Υποβολλή',
        NO_ACCOUNT: 'Δεν έχετε λογαριασμό?',
        SIGNUP_BUTTON: 'Εγγραφείτε',
        FORGOT_BUTTON: 'Ξεχάσατε τον κωδικό',
        BACK_BUTTON: 'Πίσω',
        PRIVACY: 'Μυστικότητα',
        LEGAL: 'Νομικός',
        CONTACT: 'Επικοινωνία',
      },
      LOGIN: {
        TITLE: 'Λογαριασμός σύνδεσης',
        BUTTON: 'Σύνδεση',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    FORMS: {
      SupportingDocuments: "Υποστηρικτικα Εγγραφα",
      List: "Λίστα ",
      Cancel: "Άκυρο ",
      Save: "Αποθήκευση ",
      TotalRecords: "Σύνολο Εγγραφών ",
      Loading: "Φόρτωση ",
      AdvanceSearch: "Σύνθετη Αναζήτηση ",
      Create: "Δημιουργία ",
      Edit: "Μεταβολή ",
      Delete: "Διαγραφή ",
      Export: "Εξαγωγή ",
      TheFieldWasEnteredCorrect: "Το πεδίο καταχωρήθηκε σωστά ",
      TheFieldIsRequired: "Το πεδίο είναι υποχρεωτικό ",
      ShouldHaveMoreSymbols: "Θα πρέπει να έχει περισσότερα σύμβολα ",
      ShouldHaveMaximumSymbols: "Πρέπει να έχει τα μέγιστα σύμβολα ",
      ShouldBeEmail: "Πρέπει να είναι ορθό email",
      HasBeenAdded: " προστέθηκε ",
      HasBeenUpdated: " άλλαξε ",
      HasBeenDeleted: " διαγράφθηκε ",
      HasBeenEdited: " μεταβλήθηκε ",
      AreYouSureYouWantToDelete: "Θέλετε να γίνει η διαγραφή; ",
      ClearGridFilters: "Καθαρισμός Φίλτρων ",
      ClearFilters: "Καθαρισμός Φίλτρων ",
      Search: "Αναζήτηση ",
      General: "Γενικά ",
      Mail: "Αλληλογραφία ",
      Contact: "Επικοινωνία ",
      Other: "Λοιπά ",
      Reset: "Επαναφορά ",
      Back: "Πισω ",
      Map: "Χάρτης ",
      Refresh: "Ανανέωση ",
      CheckAll: "Επιλογή Όλων ",
      FirstParty: "ΠΡΩΤΟΣ/Η ΣΥΖΗΓΟΣ",
      SecondParty: "ΔΕΥΤΕΡΟΣ/Η ΣΥΖΗΓΟΣ",
      Witnesses: "ΜΑΡΤΥΡΕΣ",
      UnCheckAll: "Απο-επιλογή Όλων ",
      Select: "Επιλογή ",
      SaveDraft: "Υποβολή σαν Πρόχειρο",
      AreYouSure: "Είστε σίγουροι; ",
      YouWonotBeAbleToRevertThis: "Δεν θα μπορείτε να επαναφέρετε ",
      Confirm: "Επιβεβαίωση ",
      Success: "Επιτυχία ",
      View: "Προβολή ",
      Reduction: "Μείωση ",
      Increase: "Αύξηση ",
      QueryParameters: "Παράμετροι Ερωτήματως ",
      Exit: "Εξοδος",
      Add: "Προσθήκη",
      Insert: "Εισαγωγή ",
      Wizard: "Βήματα ",
      ResetPassword: "Αλλαγή Κωδικου",
      Download: "Κατέβασμα ",
      HasBeenExecuted: " Εκτελέσθηκε ",
      Close: "Κλείσιμο ",

    },
    ETC: {
      AccountNumber: "Αριθμός Λογαριασμού ",
      Active: "Ενεργό ",
      Address: "Διεύθυνση ",
      Amount: "Ποσό ",
      AmountPerc: "Ποσό % ",
      Area: "Περιοχή ",
      BirthDate: "Ημερομηνία Γέννησης ",
      Block: "Οικοδομικό Τετράγωνο ",
      ChargeAllBeforeNewPeriod: "Απαιτείται Xρέωση Όλων πριν το Κλείσιμο της Περιόδου ",
      Citizen: "Δημότης ",
      Citizens: "Δημότες ",
      Code: "Κωδικός ",
      Comments: "Σχόλια",
      Company: "Εταιρεία",
      CompanyShareCapital: "Αριθμός Μετοχών Εταιρείας ",
      ContactPerson: "Πρόσωπο Επικοινωνίας ",
      Countries: "Χώρες ",
      Country: "Χώρα ",
      Website: "Δικτυακός Τόπος",
      AgencyCode: "Κωδικός Πρακτορείου  ",
      OfficePhone: "Τηλέφωνο γραφείου",
      CountryForReports: "Χώρα Εκτυπώσεων ",
      CreditAmount: "Ποσό Πίστωσης ",
      Current: "Τρέχουσα ",
      Date: "Ημερομηνία ",
      DebitAmount: "Ποσό Χρέωσης ",
      Description: "Περιγραφή",
      DirectorIDCard: "Ταυτότητα Δ/ντή ",
      DirectorMailArea: "Περιοχή Αλληλογραφίας Δ/ντή ",
      DirectorMailPostalCode: "Ταχυδρομικός Κωδικός Αλληλογραφίας Δ/ντή ",
      DirectorMailPremiseInfo: "Πληροφορίες Υποστατικού Αλληλογραφίας Δ/ντή ",
      DirectorMailRoadName: "Όνομα Δρόμου Αλληλογραφίας Δ/ντή ",
      DirectorMailRoadNumber: "Αριθμός Δρόμου Αλληλογραφίας Δ/ντή ",
      DirectorName: "Όνομα Διευθυντή ",
      Email: "Ηλεκτρονική Διεύθυνση ",
      Embassies: "Πρεσβίες ",
      Embassy: "Πρεσβία ",
      Eterodimotis: "Ετεροδημότης ",
      FathersName: "Όνομα Πατέρα ",
      Fax: "Τηλεομοιότυπο (Φαξ) ",
      FirstName: "Όνομα ",
      FlatNumber: "Αριθμός Διαμερίσματος ",
      FromDate: "Από Ημερομηνία ",
      FullName: "Πλήρες Ονοματεπώνυμο ",
      HasMaxAmt: "Έχει μέγιστο ποσό ",
      HasPercentPenalty: "Επιβάρυνση ",
      HasPolyDiscount: "Έκπτωση Πολυτέκνου ",
      HasTaxFixedAmt: "Σταθερό ποσό φορολογίας ",
      HasTriteknosDiscount: "Έκπτωση τρίτεκνου ",
      HasVat: "Έχει ΦΠΑ ",
      IdCard: "Αριθμός Ταυτότητας ",
      IdCardCountry: "Χώρα Κάρτας Ταυτοποίησης ",
      IdCardType: "Είδος Ταυτοποίησης ",
      Invoice: "Τιμολόγιο ",
      InvoiceDescriptionType: "Ειδος Τιμολογίου ",
      Invoices: "Τιμολόγια ",
      Kind: "Είδος ",
      Language: "Γλώσσα ",
      Languages: "Γλώσσες ",
      LastBankPayDate: "Τελευταία Ημερομηνία Πληρωμής Τράπεζας ",
      LastName: "Επώνυμο ",
      LastPayDate: "Τελευταία Ημερομηνία Πληρωμής ",
      LineId: "Αριθμός Γραμμής ",
      MaritalStatus: "Οικογενειακή Κατάσταση ",
      MaritalStatusDate: "Ημερομηνία Οικογενειακής Κατάστασης ",
      Mobile: "Κινητό Τηλέφωνο ",
      MothersName: "Όνομα Μητέρας ",
      Name: "Όνομα ",
      NickName: "Προσωνύμιο ",
      Number: "Αριθμός ",
      NumberOfEmployees: "Αριθμός Εργαζομένων ",
      OnlinePseudoId: "Ψευδοκωδικός ",
      OperationStopDate: "Ημεομηνία Λήξης Εργασιών ",
      ParcelNumber: "Αριθμός Δέματος ",
      Payer: "Πληρωτής ",
      PayerTaxes: "Φορολογίες ",
      PenaltyImposed: "Επιβολή Ποινής ",
      Period: "Περίοδος ",
      PeriodStatus: "Καταστάση Περιόδου ",
      PeriodStatuses: "Καταστάσεις Περιόδου ",
      Periods: "Περίοδοι ",
      Phone: "Τηλέφωνο ",
      Plan: "Σχέδιο ",
      Polyteknos: "Πολύτεκνος ",
      PolyteknosIdNumber: "Αριθμός Πολυτέκνου ",
      PostRouteCode: "Κωδικός Διαδρομής ",
      PostalCode: "Ταχυδρομικός Κωδικός ",
      Premise: "Υποστατικό ",
      Premises: "Υποστατικά ",
      PremiseInfo: "Πληροφορίες Υποστατικού ",
      PremisesDetail: "Ανάλυση Υποστατικών ",
      PreviousPeriod: "Προηγούμενη Περίοδος ",
      PropertySequenceNumber: "Μοναδικός Αριθμός Υποστατικού ",
      Prosecute: "Διώκει ",
      RoadName: "Όνομα Δρόμου ",
      RoadNumber: "Αριθμός Δρόμου ",
      Sex: "Φύλο ",
      Sheet: "Φύλλο ",
      SiNumber: "Αριθμός Κοινωνικών Ασφαλίσεων",
      SpousesName: "Όνομα Συζύγου ",
      Tax: "Φόρος ",
      TaxBasedOnPortion: "Φορολογία Ανά Μερίδιο ",
      TaxComments: "Σχολια Φορολογίας ",
      TaxMethod: "Μέθοδος Φορολόγησης ",
      TaxName: "Ονομα Φορολογίας ",
      TaxParametersAndAmounts: "Παράμετροι και ποσά ",
      TaxSubCategory: "Υποκατηγορία Φορολογίας ",
      TaxType: "Τυπος Φορολογίας ",
      Taxable: "Φορολογούμενος ",
      Taxes: "Φόροι ",
      ToDate: "Έως Ημερομηνία ",
      Transaction: "Συναλλαγή ",
      Transactions: "Συναλλαγές ",
      Triteknos: "Τρίτεκνος ",
      TriteknosIdNumber: "Αριθμός Τριτέκνου ",
      Vat: "ΦΠΑ ",
      VoteBookNumber: "Εκλογικός Αριθμός ",
      Year: "Έτος ",
      TempTaxed: "Προσωρινή Φορολόγηση ",
      Taxed: "Φορολογήθηκε ",
      Adder: "Αθροιστής ",
      Adders: "Αθροιστές ",
      TransactionJob: "Εργασία Συναλλαγής ",
      TransactionJobs: "Εργασίες Συναλλαγών ",
      UserTransaction: "Είναι Συναλλαγή Χρήστη ",
      FixedAmount: "Σταθερό Ποσό ",
      VatTransaction: "Συναλλαγή ΦΠΑ ",
      ArrearsTransaction: "Συναλλαγή Καθυστερίσεων ",
      PenaultyTransaction: "Ποινική Συναλλαγή ",
      VatParentTransactionJob: "Μητρική Συναλλαγή ΦΠΑ ",
      Credit: "Πίστωση ",
      Debit: "Χρέωση ",
      Field: "Πεδίο ",
      Type: "Τύπος ",
      VatPercentage: "Ποσοστό ΦΠΑ (%) ",
      Role: "Ρόλος ",
      Roles: "Ρόλοι ",
      User: "Χειριστής ",
      Users: "Χειριστές ",
      UserName: "Όνομα Χειριστή ",
      Password: "Μυστικός Κωδικός ",
      Allowed: "Επιτρέπεται ",
      Group: "Ομάδα ",
      UseAccountAndPasswordToContinue: "Χρησιμοποιήστε λογαριασμό και κωδικό πρόσβασης για να συνεχίσετε. ",
      Welcome: "Καλώς ήλθατε ",
      ForgotPassword: "Ξεχάσατε τον μυστικό κωδικό ",
      SignIn: "Συνδεθείτε ",
      Hi: "Γειά ",
      UserProfile: "Προφίλ Χρήστη ",
      SignOut: "Αποσύνδεση ",
      ForbiddenService: "Απαγορευμένη Υπηρεσία ",
      UnexpectedError: "Απρόσμενο Σφάλμα ",
      SomethingWentWrong: "Κάτι πήγε στραβά... ",
      SpecialRights: "Ιδιαίτερα Δικαιώματα ",
      Permission: "Δικαίωμα ",
      Permissions: "Δικαιώματα ",
      ImportedData: "Εισηγμένα Δεδομένα ",
      SpatilReport: "Χωρομετρική Αναφορά ",
      RegistrationNumber: "Αριθμός Εγγραφής ",
      Share: "Μερίδιο ",
      Owner: "Ιδιωκτήτης ",
      PremiseType: "Τύπος Υποστατικού ",
      District: "Περιοχή ",
      Road: "Οδός ",
      Village: "Χωριό ",
      SheetsPlan: "Φύλλο Σχεδίου ",
      OccupationStatus: "Κατάσταση Eπαγγέλματος ",
      RoadSupplementaryNumber: "Συμπληρωματικός Aριθμός Oδού ",
      CourtName: "Όνομα Κτιρίου ",
      PropertyBlockNumber: "Αριθμός Aποκλεισμού Iδιοκτησίας ",
      Zone: "Ζώνη ",
      ValuationWhole: "Ολόκληρη Αποτίμηση ",
      PropertyExtent: "Έκταση Iδιοκτησίας ",
      ShareNominator: "Κοινή Oνομασία ",
      ShareDenominator: "Κοινός Παρονομαστής ",
      UnregisteredEntry: "Μη Καταχωρημένο ",
      Rented: "Νοικιασμένο ",
      Completed: "Ολοκληρωμένο ",
      TaxCategory: "Κατηγορία Φορολογίας ",
      TaxCategories: "Κατηγορίες Φορολογίας ",
      TaxSubCategories: "Υποκατηγορίες Φορολογίας ",
      Detail: "Ανάλυση ",
      ParametricElements: "Παραμετρικά Στοιχεία ",
      BillingDetails: "Λεπτομέρειες Χρεώσεων ",
      Select: "Επιλογή ",
      TaxationAddition: "Προσθήκη Φορολογίας ",
      TaxationAdditionWithCategory: "Προσθήκη Φορολογίας με Κατηγορία ",
      FirstParty: "Πρώτη/ος Σύζηγος",
      SecondParty: "Δέυτερη/ος Σύζηγος",
      FirstPartyName: "Όνομα Πρώτης/ου Συζήγου",
      SecondPartyName: "Όνομα Δέυτερης/ου Συζήγου",
      AgencyName: " Όνομα πρακτορείου",
      CeremonyPlaceName: "Χώρος τελετής",
      StatusDescription: "Κατάσταση",
      ReservationDateTimeTo: "Ελάχιστη Ημερ. Κράτησης",
      ReservationDateTimeFrom: "Μέγιστη Ημερ. Κρατησης",
      ReservationDateTime: "Ημερομηνία",
      WeddingDateTimeTo: "Ελάχιστη Ημερ. Γάμου",
      WeddingDateTimeFrom: "Μέγιστη Ημερ. Γάμου",
      WeddingDateTime: "Ημερομηνία Γαμου",
      WeddingReservations: "Κρατήσεις Γάμου",
      LicenceOrWedding: "Αδεια η Γάμος",
      Wedding: "Γάμος",
      Weddings: "Γάμοι",
      Licence: "Αδεια",
      Status: "Κατάσταση",
      AgencyRefNo: "Αρ. Πρακτορίου",
      PassportNo: "Αρ. Διαβατηρίου",
      Nationality: "Υπηκοότητα",
      LicNo: "Αρ. Άδειας",
      Age: "Ηλικία",
      PlaceOfBirth: "Τόπος Γέννησης",
      ContactAddress: "Διέυθυνση Αλληλογραφίας",
      PermanentAddress: "Μόνιμη Διέυθυνση",
      PassportDateOfIssue: "Ημερ. Έκδοσης Διαβατηρίου",
      PassportCountry: "Χώρα Έκδοσης Διαβατηρίου",
      DateOfArrival: "Ημερ. Άφιξης",
      BirthDateAbr: "Ημερ. Γεννησης",
      FatherName: "Όνομα Πατέρα",
      FatherProf: "Απασχόληση Πατέρα",
      MotherName: "Όνομα Μητέρας",
      MotherProf: "Απασχόληση Μητέρας",
      NoOfGuests: "Αρ. Καλεσμένων",
      VisitDate: "Ημερ. Κράτησης",
      NoticeDate: "Ημερ. Επίσκεψης",
      WeddingOfficer: "Λειτουργός Τέλεσης Γάμων",
      CertificateOfficer: "Πιστοποιών Λειτουργος",
      CertOfficerFee: "Ποσό Πιστοποιητικού",
      NoticeOfficer: "Λειτουργός Γάμου",
      InternalComments: "Εσσωτερικά Σχόλια",
      FirstWitness: "Πρώτος Μάρτυρας",
      SecondWitness: "Δέυτερος Μάρτυρας",
      ThirdWitness: "Τρίτος Μάρτυρας",
      Dashboard: "Κεντρική Οθόνη Δημότη",
      Applications: "Αιτήσεις",
      Application: "Αίτηση",
      Modules: "Υποσυστήματα",
      Quicklinks: "Συντομέυσεις",
      SelectSubjectOfApplication: "Επιλέξτε το θέμα της αίτησης σας",
      ApplicationId: "Αριθμός Αιτησης",
      ApplicationType: "Είδος αίτησης",
      CannotUpdateSubmitedForm: "Η αίτηση έχει υποβλήθει. Μή επιτρεπτό.",
      Functions: "Λειτουργίες ",
      Taxation: "Φορολογία ",
      Informations: "Πληροφορίες ",
      InTotal: "Συνολικά ",
      Draft: "Πρόχειρο ",
      Final: "Οριστική ",
      TempTransactions: "Προσωρινές Συναλλαγές ",
      Pending: "Εκκρεμότητα ",
      Exclusively: "Αποκλειστικά ",
      ThisEntryHasBeenTaxedBefore: "Αυτή η εγγραφή έχει φορολογηθεί στο παρελθόν ",
      Temporary: "Προσωρινή ",
      Oops: "Ωχ ",
      Banks: "Τράπεζες ",
      Bank: "Τράπεζα ",
      Bic: "BIC",
      BankAccount: "Τραπεζικός Λογαριασμός ",
      BankAccounts: "Τραπεζικοί Λογαριασμοί ",
      PaymentMethods: "Μέθοδοι Εισπράξεων ",
      PaymentMethod: "Μέθοδος Είσπραξης ",
      Payments: "Εισπράξεις ",
      Payment: "Είσπραξη ",
      ChequeNo: "Αριθμός Επιταγής ",
      ExpirationDate: "Ημ. Λήξης ",
      Cash: "Μετρητά ",
      Cheque: "Επιταγή ",
      Card: "Κάρτα ",
      DynamicReports: "Δυναμικές Αναφορές ",
      DynamicReport: "Δυναμική Αναφορά ",
      Instructions: "Οδηγίες ",
      Query: "Ερώτημα ",
      SaveOnHistory: "Αποθήκευση στο Ιστορικό ",
      QueryParameters: "Παράμετροι Ερωτήματος ",
      DisplayName: "Όνομα Εμφάνισης ",
      ParameterName: "Όνομα Παραμέτρου ",
      Value: "Τιμή ",
      Execution: "Εκτέλεση ",
      ExecutionName: "Όνομα Εκτέλεσης ",
      WeddingReservation: " Κράτηση Γάμου",
      General: "Γενικά",
      ReservationStatuses: "Κατάσταση Κρατήσεων",
      InApp: "InApp",
      Sms: "SMS",
      Push: "Push",
      Message: "Μήνυμα ",
      Messages: "Μηνύματα ",
      Title: "Τίτλος ",
      Priority: "Προτεραιότητα ",
      Icon: "Εικονίδιο ",
      Body: "Κείμενο ",
      Tags: "Ετικέτες ",
      Recipients: "Παραλήπτες ",
      Image: "Εικόνα ",
      YouMustSelectAnImage: "Πρέπει να επιλέξετε μια εικόνα ",
      OnlyImagesAreSupported: "Υποστηρίζονται μόνο εικόνες ",
      Notifications: "Ειδοποιήσεις ",
      ScheduleRunTime: "Χρόνος Εκτέλεσης ",
      Incoming: "Εισερχόμενα ",
      Outgoing: "Εξερχόμενα ",

      Business: "Επάγγελμα ",
      CustomerView: "Προβολή Πελάτη ",
      CustomersView: "Προβολή Πελατών ",
      City: "Πόλη ",
      Balance: "Υπόλοιπο ",
      Discount: "Εκπτωση ",
      Manager: "Υπεύθυνος ",
      TaxOffice: "Εφορία ",
      VatDescription: "Καθεστώς ΦΠΑ ",
      PriceListDescription: "Τιμοκατάλογος ",
      PaymentTypeDescription: "Πληρωμή ",
      GeographicalAreaDescription: "Γεωγραφική Περιοχή ",
      SellerCode: "Κωδικός Πωλητή ",
      SellerName: "Όνομα Πωλητή ",
      SupplierView: "Προβολή Προμηθευτή ",
      SuppliersView: "Προβολή Προμηθευτών ",
      NetAmount: "Καθαρή Αξία ",
      Series: "Σειρά ",
      HelpCode: "Βοηθητικός Κωδικός ",
      MeasurementUnit: "Μον. Μέτρ. ",
      Category: "Κατηγορία ",
      WholesaleRate: "% Χονδρικής ",
      PurchasePrice: "Τιμή Αγοράς ",
      WholesalePrice: "Τιμή Χονδρικής ",
      RetailPrice: "Τιμή Λιανικής ",
      SupplierName: "Προμηθευτής ",
      StockItemsView: "Προβολή Ειδών ",
      Quantity: "Ποσότητα ",
      VatAmount: "ΦΠΑ ",
      TotalAmount: "Σύνολο ",
      Previous: "Προηγούμενη ",
      Seller: "Πωλητής ",
      SendEmail: "Αποστολή Email ",
      Cost: "Κόστος ",
      MappingReference: "Διασύνδεση ",
      Answer: "Απάντηση ",
      Answers: "Απαντήσεις ",
      Question: "Ερώτηση ",
      Questions: "Ερωτήσεις ",
      QuestionGroup: "Ομάδα Ερώτησης ",
      QuestionGroups: "Ομάδες Ερωτήσεων ",
      Questionnaire: "Ερωτηματολόγιο ",
      Questionnaires: "Ερωτηματολόγια ",
      Afm: "ΑΦΜ ",
      Color: "Χρώμα ",
      Order: "Παραγγελία ",
      Configurator: "Διαμορφωτής ",
      NewRecord: "Νέα Εγγραφή ",
      Markup: "Markup ",
      DataType: "Τύπος Δεδομένων ",
      ProductCategories: "Κατηγορίες Προιόντων ",
      ProductCategory: "Κατηγορία Προιόντων ",
      Offers: "Προσφορές ",
      Offer: "Προσφορά ",
      Customer: "Πελάτης ",
      DeliveryDate: "Ημερομηνία Παράδοσης ",
      TransformToOrder: "Μετασχηματισμός σε Παραγγελία ",
      AnswerGroups: "Ομάδες Απαντήσεων ",
      AnswerGroup: "Ομάδα Απαντήσεων ",
      Exceptions: "Εξαιρέσεις ",
      BulkAddition: "Μαζική Προσθήκη ",
      ConnectionProblemTitle: "Πρόβλημα Σύνδεσης ",
      ConnectionProblemText: "Το Όνομα Χειριστή ή ο Μυστικός Κωδικός είναι λάθος. Προσπαθήστε ξανά.",
      Applicants: "Επαφές ",
      Applicant: "Επαφή ",
      Notes: "Σημειώσεις ",
      PostofficeAddress: "Διεύθυνση Ταχυδρομείου ",
      Province: "Νομός ",

      Professions: "Επαγγέλματα ",
      Profession: "Επάγγελμα ",
      RegionalUnits: "Νομοί ",
      RegionalUnit: "Νομός ",
      Sources: "Πηγές ",
      Source: "Πηγή ",
      Consents: "Συναινεί ",
      RegistrationUser: "Χειριστής Καταγραφής ",
      SellerUser: "Πωλητής ",
      AddImage: "Προσθήκη Φωτογραφίας ",
      MyCustomer: "Πελάτης ",
      MyCustomers: "Πελάτες ",
      ImportType: "Είδος Εισαγωγής ",
      GeographicalArea: "Γεωγραφική Περιοχή ",
      ErpBalance: "ERP Υπόλοιπο ",
      MyBalance: "MY Υπόλοιπο ",
      CalculatedBalance: "Τελικό Υπόλοιπο ",
      TranCode: "Κωδ. Παραστατικού ",
      TranCnt: "Αριθμός Παραστατικού ",
      Serie: "Σειρά ",
      Aitiol: "Αιτιολογία ",
      TotYpokam: "Καθαρή Αξία ",
      TotVat: "Αξία ΦΠΑ ",
      TotAmount: "Συνολική Αξία ",
      NoSalValue: "Αφαίρεση αξίας από Πωλητή ",
      MySuppliers: "Προμηθευτές ",
      Supplier: "Προμηθευτής ",
      TransactionType: "Τύπος Κίνησης ",
      MyCustomerTransactions: "Κινήσεις Πελατών ",
      MyCustomerTransaction: "Κίνηση Πελάτη ",
      MySupplierTransactions: "Κινήσεις Προμηθευτών ",
      MySupplierTransaction: "Κίνηση Προμηθευτή ",
      MySellers: "Πωλητές ",
      MySeller: "Πωλητής ",
      ExternalReference: "Κωδικός Διασύνδεσης ",
      All: "Όλα ",
      Yes: "Ναί",
      No: "Όχι",
      MyStockItemGroups: "Ομάδες ",
      MyStockItemGroup: "Ομάδα ",
      MyCosts: "Εξοδα ",
      MyCost: "Εξοδο ",
      MyCustomerTransactionType: "Τύπος Κίνησης Πελάτη ",
      MyCustomerTransactionTypes: "Τύποι Κίνησης Πελατών ",
      IsInventory: "Απογραφή",
      NoCash: "Όχι Ταμείο",
      MySupplierTransactionTypes: "Τύποι Κίνησης Προμηθευτών ",
      MySupplierTransactionType: "Τύπος Κίνησης Προμηθευτή ",
      ΜyCustomerGeographicalAreas: "Γεωργαφικές Περιοχές ",
      ΜyCustomerGeographicalArea: "Γεωργαφική Περιοχή ",
      IsPayment: "Πληρωμή ",
      ΜyCustomerPaymentType: "Τρόπος Πληρωμής ",
      ΜyCustomerPaymentTypes: "Τρόποι Πληρωμής ",
      Inventory: "Απογραφή ",
      noCash: "Όχι Ταμείο ",
      Reason: "Αιτιολογία ",
      MyCustomerPaymentType: "Τρόπος Πληρωμής/Είσπραξης Πελάτη ",
      MyCustomerPaymentTypes: "Τρόποι Πληρωμής/Είσπραξης Πελατών ",
      MySupplierPaymentType: "Τρόπος Πληρωμής/Είσπραξης Προμηθευτή ",
      MySupplierPaymentTypes: "Τρόποι Πληρωμής/Είσπραξης Προμηθευτών ",
      SubSystem: "Υποσύστημα ",
      MyStockItemGroupTransactionTypes: "Τύποι Κίνησης Ομάδων (Υλικών) ",
      MyStockItemGroupTransactionType: "Τύπος Κίνησης Ομάδων (Υλικών) ",
      TranType: "Είδος Κίνησης ",
      MyStockItemGroupTransactions: "Κινήσεις Ομάδων (Υλικών) ",
      MyStockItemGroupTransaction: "Κίνηση Ομάδας (Υλικών) ",
      Purchases: "Αγορές ",
      Sales: "Πωλήσεις ",
      MyReport1: "Αναφορά Αποτελεσμάτων ανά Ομάδα Ειδών ",
      Expenses: "Έξοδα ",

      MyReport2: "Αναφορά Τζίρου Ομάδων Ειδών ",
      CustomerCode: "Κωδικός Πελάτη ",
      CustomerName: "Όνομα Πελάτη ",
      CustomerGeographicalAreaCode: "Κωδικός Γεωργαφικής Περιοχής",
      CustomerGeographicalAreaName: "Όνομα Γεωργαφικής Περιοχής",
      StockItemGroupTurnover: "Τζίρος Ομάδων Ειδών ",

      Sales1: "Θωρακισμένη ",
      Sales2: "Σίτα ",
      Sales3: "Μεσόπορτα ",
      Sales4: "Κουζίνα ",
      Sales5: "Πάγια ",
      Sales6: "Laminate ",
      Sales7: "Bertolotto ",
      Sales9: "Λοιπά ",
      Sales11: "Γυάλινη ",
      Sales12: "Συνεπίπεδη ",
      Sales13: "Αξονική ",
      Sales14: "Μεταλουμίν ",
      Sales15: "Πάνελ ",

      InvolvedInTurnover: "Συμμετοχή στο Τζίρο ",
      GroupsWithInvolvedInTurnover: "Ομάδες με συμμετοχή στο Τζίρο ",

      MyReport3: "Αναφορά Τζίρου Πωλητών ",
      Turnover2019: "Τζίρος 2019 ",
      Turnover2020: "Τζίρος 2020 ",
      Turnover2021: "Τζίρος 2021 ",
      Turnover2022: "Τζίρος 2022 ",
      Turnover2023: "Τζίρος 2023 ",
      Turnover2024: "Τζίρος 2024 ",

      ImportFromErpEnd: "Η διαδικασία εισαγωγής από το Erp τελείωσε.",
      RebuildAddersEnd: "Η διαδικασία ανακατασκευής αθροιστών τελείωσε.",

      MyReport4: "Αναφορά Εισπράξεων ",
      MyReport5: "Αναφορά Υπολοίπων Πελατών",
      MyReport6: "Αναφορά Υπολοίπων Προμηθευτών ",
      MyReport7: "Αναφορά Ενηλικίωσης Υπολοίπων Πελατών ",

      UpdatesValue: "Αξία ",
      UpdatesQuantity: "Ποσότητα ",
      ClientDeactivation: "Απενεργοποίηση Πελατών ",
      AreActive: "Ενεργοί ",
      AreInactive: "Ανενεργοί ",
      AreAll: "Όλοι ",
      SellersInvolvedInTurnover: "Πωλητές με συμμετοχή στο Τζίρο",
      TotPayment: "Σύνολο Εισπράξεων ",
      PaymentAmount: "Ποσό Είσπραξης ",
      AccountingTab: "Καρτέλα",
      ProgressiveBalance: "Υπόλοιπο ",
      Actions: "Ενέργειες ",
      IWantToChangeMyPassword: "Θέλω να αλλάξω τον κωδικό μου.",
      OldPassword: "Παλιός Κωδικός",
      NewPassword: "Καινούργιος Κωδικός",
      ConfirmPassword: "Επιβαιβέωση Καινούργιου Κωδικού",
      MyStockItems: "Αποθήκη (Υλικά) ",
      MyStockItem: "Αποθήκη (Υλικό) ",
      MyOrder: "Παραγγελία ",
      MyOrders: "Παραγγελίες ",
      Basket: "Καλάθι ",
      FinishOrder: "Ολοκλήρωση Παραγγελίας ",
      TotQuantity: "Σύνολο Ποσότητας ",
      YouMmusLoginAsCustomer: "Θα πρέπει να συνδεθείτε σαν πελάτης",
      ContactEmails: "Email Επικοινωνιας ",
      MyStockItemMeasurementUnits: "Μονάδες Μέτρησης ",
      MyStockItemMeasurementUnit: "Μονάδα Μέτρησης ",
      ZeroQty: "Υπάρχουν Είδη με Μηδενική Ποσότητα ",
      Comment: "Παρατηρήσεις ",
      Transferred: "Μεταφέρθηκε",

      // Eoda
      EodaMember: "Μέλος ",
      EodaMembers: "Μέλη ",
      CallNumber: "Κωδικός Κλήσης ",
      Level: "Βαθμίδα ",
      ParameterValues: "Τιμές Χαρακτηριστικών ",
      Education: "Εκπαίδευση ",
      Educations: "Εκπαιδεύσεις ",
      Location: "Τοποθεσία ",
      EodaAction: "Δράση ",
      EodaActions: "Δράσεις ",
      IdNumber: "Αριθμός Ταυτότητας ",
      LastNameEn: "Επώνυμο (Αγγλικά) ",
      FirstNameEn: "Όνομα (Αγγλικά) ",
      RegisterNumber: "Κωδικός Καταγραφής ",
      RegisterDate: "Ημερομηνία Εγγραφής ",
      JobTelephone: "Τηλέφωνο Εργασίας ",
      EmergencyFullName: "Ονοματεπώνυμο Προσώπου Ανάγκης ",
      EmergencyMobile: "Κινητό Προσώπου Ανάγκης  ",
      EmergencyTelephone: "Τηλέφωνο Προσώπου Ανάγκης ",
      EmergencyRelationship: "Σχέση με Πρόσωπο Ανάγκης ",
      MediNotes: "Σημειώσεις Υγείας ",
      EvaluationNotes: "Σημειώσεις Αξιολόγησης ",
      EodaSocialOfferActions: "Δράσεις Κοινωνικής Προσφοράς ",
      EodaSocialOfferAction: "Δράση Κοινωνικής Προσφοράς ",
      EodaEnterprises: "Επιχειρήσεις ",
      EodaEnterprise: "Επιχείρηση ",
      EodaPractices: "Ασκήσεις ",
      EodaPractice: "Άσκηση ",
      EodaFinTransactions: "Οικονομικές Κινήσεις ",
      SubscriptionYear: "Έτος Συνδρομής ",
      EmergencyDetails: "Στοιχεία Ανάγκης ",
      CommunicationDetails: "Στοιχεία Επικοινωνίας ",
      EodaSectors: "Τομείς ",
      EodaSector: "Τομέας ",
      EodaDrivingLicense: "Άδεια Οδήγησης ",
      EodaSpeciality: "Ειδικότητα ",
      EodaLevels: "Βαθμίδες ",
      EodaLevel: "Βαθμίδα ",
      EodaSpecialities: "Ειδικότητες ",
      EodaEducations: "Εκπαιδεύσεις ",
      EodaEducation: "Εκπαίδευση ",
      History: "Ιστορικό ",
      EodaFirstAid: "Α' Βοήθειες ",
      EodaFirstAids: "Α' Βοήθειες ",
      CertificationDate: "Ημ. Πιστοποίησης ",
      EodaCards: "Ταυτότητες (Κάρτες) ",
      PrintDate: "Ημερομηνία Εκτύπωσης ",
      Attachments: "Συνημμένα ",
      SelectFile: "Επιλεγμένο Αρχείο ",
      OrDropFilesHere: "Επιλέξτε ή σύρρετε τα αρχεία εδώ ",
      SelectedFile: "Επιλεγμένα αρχεία ",
      Size: "Μέγεθος ",
      LastModifiedDate: "Τελευταία μεταβολή ",
      UploadFile: "Ανέβασμα Αρχείου ",
      TheProcessWasCompletedSuccessfully: "Η διαδικασία ολοκληρώθηκε επιτυχώς ",
      FileName: "Όνομα Αρχείου ",
      ContentType: "Τύπος Αρχείου ",
      LevelHistory: "Ιστορικό Βαθμίδων ",
      ChangeLevel: "Αλλαγή Βαθμίδας ",
      EducationGroups: "Ομάδες Εκπαιδεύσεων ",
      NumberOfActions: "Αριθμός Δράσεων ",
      NumberOfPractices: "Αριθμός Ασκήσεων ",
      Main: "Βασική ",
      EodaS101s: "Σ101 ",
      EodaS101: "Σ101 ",
      Eep: "Επικεφαλής Επιχείρησης Πεδίου ",
      VehicleRegisterNumber: "Αριθμός Αυτοκινήτου",
      VehicleManufacturer: "Κατασκευαστής Αυτοκινήτου",
      VehicleColor: "Χρώμα Αυτοκινήτου",
      VehicleType: "Τύπος Αυτοκινήτου",
      VehicleFreeSeats: "Θέσεις Αυτοκινήτου",
      ArrivalDateTime: "Άφιξη",
      DepartureDateTime: "Αναχώρηση",
      MyCompetitions: "Ανταγωνισμός ",
      MyCompetition: "Ανταγωνισμός ",
      MyGradings: "Βαθμολογίες ",
      MyGrading: "Βαθμολογία ",
      MyMeetingTypes: "Τύποι Συναντήσεων ",
      MyMeetingType: "Τύπος Συνάντησης ",
      MyMeetingStatuses: "Καταστέστεις Συναντήσεων ",
      MyMeetingStatus: "Κατάσταση Συνάντησης ",
      MyMeetings: "Συναντήσεις ",
      MyMeeting: "Συνάντηση ",
      Problems: "Προβλήματα ",
      Problem: "Πρόβλημα ",
      Contact: "Επαφή ",
      Quantities: "Ποσότητες ",
      Discounts: "Εκπτώσεις ",
      Price: "Τιμή ",
      EodaMaterials: "Υλικά ",
      EodaEquipments: "Εξοπλισμοί ",
      Provider: "Φορέας ",
      Warehouse: "Αποθήκη ",
      EodaMemberFinTransactions: "Οικονομικές Κινήσεις ",
      EodaMemberFinTransaction: "Οικονομική Κίνηση ",
      ReceiptNumber: "Αριθμός Απόδειξης ",
      EodaFinTransactionTypes: "Τύποι Κίνησης ",
      EodaFinTransactionType: "Τύπος Κίνησης ",
      PleaseSelectEodaLevel: "Παρακαλώ επιλέξτε Βαθμίδα ",
      MyStockItemTransactionTypes: "Τύποι Κίνησης Υλικών Αποθήκης ",
      MyStockItemTransactionType: "Τύπος Κίνησης Υλικών Αποθήκης ",
      MyStockItemTransactions: "Κινήσεις (Υλικών) ",
      MyStockItemTransaction: "Κίνηση (Υλικών) ",
      MigrateStockItemsFromErp: "Μεταφορά Ειδών από το ERP ",
      MyWarehouses: "Αποθηκευτικοί Χώροι ",
      MyWarehouse: "Αποθηκευτικός Χώρος ",
      MyBulkStockItemTransactions: "Μαζικές Κινήσεις Υλικών Αποθήκης ",
      MyBulkStockItemTransaction: "Μαζική Κίνηση Υλικών Αποθήκης ",
      DmrRadioId: "Radio Id ",
      DmrCallAlert: "Call Alert ",
      DmrFname: "First Name ",
      DmrSurname: "Surname ",
      DmrName: "Full Name ",
      DmrCountry: "Country ",
      DmrCity: "City ",
      DmrState: "State ",
      DmrRemarks: "Remarks ",
      DmrServer: "Dmr Server Details ",
      ExportDmrServerJsonFile: "Εξαγωγή JSON για τον DMR Server (users.json) ",
      ExportDmrIdsFile: "Εξαγωγή αρχείου (DMRIds.dat) ",
      ExportDigitalContactListFile: "Εξαγωγή αρχείου (DigitalContactList.csv) ",
      ExportContactFile: "Εξαγωγή αρχείου (Contact.csv) ",
      EodaEducationHistory: "Ιστορικό Εκπαίδευσης ",
      EodaPracticeHistory: "Ιστορικό Άσκησης ",
      EodaActionHistory: "Ιστορικό Δράσης ",
      EodaSocialOfferActionHistory: "Ιστορικό Δράσης Κοινωνικής Προσφοράς ",
      EodaEnterpriseHistory: "Ιστορικό Επιχείρησης ",
      EodBranchs: "Περιοχές ΕΟΔ ",
      EodBranch: "Περιοχή ΕΟΔ ",
      EodDmrServerUsers: "Χειριστές DMR ",
      EodDmrServerUser: "Χειριστής DMR ",
      Gps: "GPS ",
      RadioAmateurId: "Ραδιοερασιτέχνη Id ",
      RadioAmateurCallSign: "Ραδιοερασιτέχνη CallSign ",
      Occupation: "Ενασχόληση ",
      TaskScheduler: "Χρονοδιάγραμμα Eργασιών ",
      TaskSchedulers: "Χρονοδιαγράμματα Eργασιών ",
      Task: "Εργασία ",
      UniqueName: "Μοναδικό Όνομα ",
      WhenToEnable: "Πότε να ενεργοποιήσετε αυτήν την εργασία? ",
      SchedulerInformation: "Πληροφορίες προγραμματισμού ",
      FromHour: "Από Ώρα",
      ToHour: "Εώς Ώρα",
      FeatureType: "Τύπος Χαρακτηριστικού ",
      HangfireInformation: "Πληροφορίες Hangfire ",
      LastExecution: "Τελευταία Εκτέλεση ",
      NextExecution: "Επόμενη Εκτέλεση ",
      Cron: "Cron ",
      LastJobState: "Κατάσταση τελευταίας εργασίας ",
      Queue: "Ουρά ",
      RepeatEvery: "Επανάληψη Κάθε ",
      Interval: "Διάστημα ",
      Time: "Ώρα ",
      StartDateTime: "Ημερομηνία & Ώρα Έναρξης ",
      EndDateTime: "Ημερομηνία & Ώρα Λήξης ",
      StartDate: "Ημερομηνία Έναρξης ",
      EndDate: "Ημερομηνία Λήξης ",
      EodaSchools: "Σχολεία/Πιστοποιήσεις ",
      EodaSchool: "Σχολείο/Πιστοποίηση ",
      EodaDrivingLicenses: "Άδειες Οδήγησης ",
      EodaDrivingLicens: "Άδεια Οδήγησης ",
      EodaEducationGroups: "Ομάδες Εκπαιδεύσεων ",
      EodaEducationGroup: "Ομάδα Εκπαίδευσης ",
      DownloadPdfProfile: "Κατέβασμα Προφίλ σε PDF ",
      EodaVaccines: "Εμβόλια ",
      EodaVaccine: "Εμβόλιο ",
      EodaEquipment: "Εξοπλισμός ",
      SerialNumber: "Σειρακός Αριθμός ",
      BrandModel: "Μάρκα/Μοντέλο",
      Created: "Δημιουργήθηκε ",
      EventEngineMessages: "Μηνύματα ",
      EventEngineMessage: "Μήνυμα ",
      EventEngineMessageTypes: "Τύποι Μηνυμάτων ",
      EventEngineMessageType: "Τύπος Μηνύματος ",
      EmailProviders: "Πάροχοι Email ",
      EmailProvider: "Πάροχος Email ",
      Host: "Διακομιστής ",
      SSL: "SSL Πιστοποιητικό ",
      DomainName: "Domain Name ",
      Port: "Πόρτα ",
      Key: "Κλειδί ",
      SmsProviders: "Πάροχοι SMS ",
      SmsProvider: "Πάροχος SMS ",
      Endpoint: "Τελική Διεύθυνση ",
      IsActive: "Ενεργό ",
      Method: "Μέθοδος ",
      From: "Από ",
      Region: "Περιοχή ",
      ActiveInactive: "Ενεργό/ Μη Ενεργό",
      Inactive: "Μη Ενεργό",
      DeleteFile: "Διαγραφή Αρχείου",
      EventEngineMessageKind: "Είδος Μηνύματος ",
      MessageDate: "Ημερομηνία Μηνύματος ",
      RecipientEmail: "Ηλεκτρονική Διεύθυνση Παραλήπτη ",
      RecipientName: "Όνομα Παραλήπτη ",
      MessageTitle: "Τίτλος Μηνυματος ",
      MessageBody: "Περιγραφή / Περιεχόμενο Μηνύματος ",
      SentDate: "Ημερομηνία Αποστολής ",
      SentDateTime: "Ημερομηνία/Ώρα Αποστολής ",
      EodaDocuments: "Αρχεία Κειμένου ",
      EodaDocument: "Αρχείο Κειμένου ",
      SendDocumentFilesByEmail: "Αποστολή Αρχείων Κειμένου με μήνυμα ",
      OtpTitle: "Μοναδικός Κωδικός Εισόδου",
      OtpText: "Για να ολοκληρώσετε την διαδικασία σύνδεσης στον λογαριασμό σας, σας παρέχουμε έναν μοναδικό κωδικό (OTP) μιας χρήσης που θα βρείτε στο email σας.",
      Submit: "Υποβολή ",
      Coordinates: "Συντεταγμένες ",
      CoordinateNorth: "Γεωγραφικό Πλάτος (Βοράς) ",
      CoordinateEast: "Γεωγραφικό Μήκος (Ανατολή) ",
      Map: "Χάρτης ",
      Distance: "Απόσταση ",
      Duration: "Διάρκεια ",
      Kilometer: "Χλμ ",
      Minutes: "Λεπτά ",
      EodaIncidents: "Περιστατικά ",
      EodaIncident: "Περιστατικό ",
      CallerName: "Ονοματεπώνυμο Καλούντα ",
      EodaIncidentMemberMobilizations: "Κινητοποίηση Μελών Περιστατικού ",
      Selected: "Επιλεγμένο ",
      AvailableFromDateTime: "Διαθεσιμότητα Από ",
      AvailableToDateTime: "Διαθεσιμότητα Έως ",
      ImportAllActiveEodaMembers: "Εισαγωγή Όλων των Ενεργών Μελών ",
      ImportActiveEodaMembersByLevel: "Εισαγωγή Ενεργών Μελών (Βαθμίδα) ",
      ImportActiveEodaMembersByLevel1: "Εισαγωγή Ενεργών Μελών (Βαθμίδα 1) ",
      ImportActiveEodaMembersByLevel2: "Εισαγωγή Ενεργών Μελών (Βαθμίδα 2) ",
      ImportActiveEodaMembersByLevel3: "Εισαγωγή Ενεργών Μελών (Βαθμίδα 3) ",
      ImportActiveEodaMembersByLevel4: "Εισαγωγή Ενεργών Μελών (Βαθμίδα 4) ",
      ImportActiveEodaMembersByLevel8: "Εισαγωγή Ενεργών Μελών (Ειδική Κατηγορία) ",
      DeleteAllEodaMembers: "Διαγραφή Όλων Χωρίς Κατάσταση ",
      SendSMS: "Αποστολή SMS ",
      StartFromOffices: "Έναρξη από Γραφεία ",
      TokenEndpoint: "Token Endpoint ",
      TrackingEndpoint: "Tracking Endpoint ",
      ClientId: "ClientId ",
      SectorHeads: "Τομεάρχες ",
      Mobilization: "Κινητοποίηση ",
      SendBulkSmsMobilization: "Μαζική Αποστολή SMS Κινητοποίησης ",
      CalculateMapDistances: "Υπολογισμός Αποστάσεων ",
      SmsLog: "Μηνύματα SMS ",
      To: "Αποστολή Σε ",
      Sent: "Απεστάλη ",
      ViewCustomer: "Προβολή Πελάτη ",
      ViewSupplier: "Προβολή Προμηθευτή ",
      LastSubscription: "Συνδρομή Τελευταία ",
      HasImage: "Έχει Εικόνα ",
      BoardOfDirectors: "ΔΣ ",
      PropertyOfBoard: "Θέση στο ΔΣ ",
      OperationsManager: "Υπεύθυνος Επιχειρήσεων ",
      RegisterDateGgpp: "Ημερομηνία Εγγραφής ΓΓΠΠ ",
      EodaGgppActions: "Δράσεις ΓΓΠΠ ",
      EodaGgppAction: "Δράση ΓΓΠΠ ",

      PasswordItems: "Κωδικοί ",
      PasswordItem: "Κωδικός ",
      Url: "Url ",

      EodaShifts: "Ομάδες Κινητοποίησης ",
      EodaShift: "Ομάδα Κινητοποίησης ",
      Details: "Λοιπά Στοιχεία ",
      MaxPersons: "Όριο Ατόμων ",
      Day: "Ημέρα ",
      EodaMemberSchedulers: "Εκτελεσμένες Βάρδιες ",
      IsFire: "Πυρόσβεση ",
      UpdateRouteeContacts: "Ενημέρωση Routee ",
      EodaShiftCreateSchedulerSlots: "Δημιουργία Slots Βαρδιών ",
      IsShift: "Έχει Βάρδιες ",
      MyReport8: "Αναφορά Εξόδων ",

      Projects: "Έργα ",
      Project: "Έργο ",
      EstimatedCompletionDate: "Πιθανή Ολοκλήρωση ",
      FolderNumber: "Αρ. Φακέλου ",
      IsMyEditor: "Διορθώνει (Erp)",
      IsRight: "Σωστό ",
      EodaMemberQuestionnaires: "Αναθέσεις Ερωτηματολογίων ",
      EodaMemberQuestionnaire: "Ανάθεση Ερωτηματολογίου ",
      Grade: "Βαθμός ",

      MyReport9: "Αναφορά Τζίρου Πωλητών / Έτος ",
      MyReport10: "Αναφορά Τζίρου Γεωγραφικών Περιοχών / Έτος ",

      GradePass: "Bαθμός Eπιτυχίας ",
      Evaluation: "Αξιολόγηση ",
      MyResult: "Αποτέλεσμα ",
      MyResults: "Αποτελέσματα ",
      SalesGrossProfitPercent: "Μικτό Κέρδος Πωλήσεων % ",
      StartingInventoryValue: "Απογραφή Έναρξης ",
      EndInventoryValue: "Απογραφή Λήξης ",
      Calculate: "Υπολογισμός ",
      ExceptSupervisor: "Εκτός Supervisor ",
    }
  },
};
