import { Component, ChangeDetectionStrategy, OnDestroy, OnInit, ElementRef, } from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
import { TranslateService } from '@ngx-translate/core';

// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as grLang } from './modules/i18n/vocabs/gr';

import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService, TableExtendedService } from './_metronic/shared/crud-table';

import DataGrid from "devextreme/ui/data_grid";
import drop_down_box from "devextreme/ui/drop_down_box";
import date_box from "devextreme/ui/date_box";

import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { AuthService } from './modules/auth';
import { CommonFunctionsService } from './modules/portal/_services/commonFunctions.service';

import { loadMessages, locale } from 'devextreme/localization';
import elMessages from "devextreme/localization/messages/el.json";

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppComponent implements OnInit, OnDestroy
{
  private unsubscribe: Subscription[] = [];

  dropDownDefaultOptions: any = {
    device: { deviceType: "desktop" },
    options: {
      stylingMode: "outlined",
      height: 34
    }
  };

  dataGridDefaultOptions: any = {
    device: { deviceType: "desktop" },
    options: {
      stylingMode: "outlined",
      columnChooser: {
        allowSearch: true,
        height: 350,
        width: 300,
        mode: "select"
      }
    }
  };

  //! Idle Timeout
  timedOut = false;
  onIdleEndService: any;
  onTimeoutService: any;
  onIdleStartService: any;
  onTimeoutWarningService: any;

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private translate: TranslateService,
    private apiService: ApiService,
    private helperFunctions: CommonFunctionsService,
    private auth: AuthService,

    //! Idle Timeout
    private idle: Idle, private keepalive: Keepalive, private element: ElementRef
  )
  {
    let subscription = router.events.subscribe((event) =>
    {
      if (event instanceof NavigationStart)
      {
        let browserRefresh = !router.navigated;

        if (browserRefresh)
        {
          var token = apiService.getAuthFromLocalStorage();

          if (token)
          {
            // helperFunctions.updateAbility();
          }
        }
      }
    });

    translate.setDefaultLang("gr");

    // register translations
    this.translationService.loadTranslations(enLang, grLang);
    this.initializeDefaultDxOptions();

    let lang = this.translationService.getSelectedLanguage();

    if (lang === 'gr')
    {
      loadMessages(elMessages);
      locale("el");
    }
    else if (lang === 'en')
    {
      locale("en");
    }
  }

  reset()
  {
    this.idle.watch();
    this.timedOut = false;
  }

  ngOnInit()
  {
    const routerSubscription = this.router.events.subscribe((event) =>
    {
      if (event instanceof NavigationEnd)
      {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() =>
        {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });

    this.unsubscribe.push(routerSubscription);

    //! Idle Timeout 

    // sets an idle timeout of 60 minutes.
    let idleTimeout = environment.IdleTimeout;

    if (idleTimeout == null)
    {
      idleTimeout = 57600;  //16 hours
    }

    this.idle.setIdle(idleTimeout);

    // sets a timeout period of 1 second.
    this.idle.setTimeout(1);

    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);

    this.onIdleEndService = this.idle.onIdleEnd.subscribe(() =>
    {
      //this.idleState = 'NO_LONGER_IDLE';
    });

    this.onTimeoutService = this.idle.onTimeout.subscribe(() =>
    {
      this.timedOut = true;

      console.log('timeout');
      this.idle.stop();

      //prevent init multiple time
      this.idle.onTimeout.observers.length = 0;
      this.idle.onIdleStart.observers.length = 0;
      this.idle.onIdleEnd.observers.length = 0;

      this.showIdleNottice();
    });

    this.onIdleStartService = this.idle.onIdleStart.subscribe(() =>
    {
      console.log("idle starting .............");
    });

    this.onTimeoutWarningService = this.idle.onTimeoutWarning.subscribe((countdown: any) =>
    {
      console.log("idle in progress");
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    /**
     * Keepalive can ping request to an HTTP location to keep server session alive
     * keepalive.request('<String URL>' or HTTP Request);
     * Keepalive ping response can be read using below option
     * keepalive.onPing.subscribe(response => {
     * Redirect user to logout screen stating session is timeout out if if response.status != 200
     * });
     */

    this.reset();
  }

  ngOnDestroy()
  {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    //! Idle Timeout
    this.onIdleEndService.unsubscribe();
    this.onTimeoutService.unsubscribe();
    this.onIdleStartService.unsubscribe();
    this.onTimeoutWarningService.unsubscribe();
  }

  initializeDefaultDxOptions()
  {
    DataGrid.defaultOptions(this.dataGridDefaultOptions);
    drop_down_box.defaultOptions(this.dropDownDefaultOptions);
    date_box.defaultOptions(this.dropDownDefaultOptions);
  }

  showIdleNottice()
  {
    if (confirm("For security reason, your session is being timed out. Click OK to login again."))
    {
      console.log("Redirect to login");
      this.auth.logout();
      document.location.reload();
    }
  }
}
