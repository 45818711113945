import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NEVER, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from '../../../../environments/environment';
import { AuthModel } from 'src/app/modules/auth/_models/auth.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { AuthService } from '../../auth';
import { TranslateService } from '@ngx-translate/core';
import { HelperFunctionsService } from '.';
import { TranslationService } from "../../i18n/translation.service";

@Injectable({
  providedIn: 'root'
})

export class ApiService
{
  constructor(
    private httpClient: HttpClient,
    private auth: AuthService,
    public translateService: TranslateService,
    public translationService: TranslationService,
    private helperFunctionsService: HelperFunctionsService,
  ) { }

  private authLocalStorageToken = `${ environment.appVersion }-${ environment.USERDATA_KEY }`;

  /*   getLanguageCode()
    {
      if (this.translateService.currentLang === "gr")
      {
        return "el-GR"
      }
      else
        if (this.translateService.currentLang === "en")
        {
          return "en-GB"
        }
        else
        {
          return "el-GR"
        }
    } */

  getLanguageCode()
  {
    if (this.translationService.getSelectedLanguage() === "gr")
    {
      return "el-GR"
    }
    else
      if (this.translationService.getSelectedLanguage() === "en")
      {
        return "en-GB"
      }
      else
      {
        return "el-GR"
      }
  }

  public getAuthFromLocalStorage(): AuthModel
  {
    try
    {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );

      return authData;
    } catch (error)
    {
      console.error(error);
      return undefined;
    }
  }

  public handleError(error: any)
  {
    console.error(error); // log to console instead
    return throwError(error);
  }

  public getHeaders(type: string)
  {
    const auth = this.getAuthFromLocalStorage();

    if (type = "blob")
    {
      let headers = {
        'Accept': 'application/json',
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode()
      }
      return headers;
    }
    else
    {
      let headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode()
      }
      return headers;
    }
  }

  public getUrl(url: string)
  {
    return environment.apiUrl + url;
  }

  //** GET */
  public fetchBlob(url): Observable<any>
  {
    const auth = this.getAuthFromLocalStorage();
    let httpOptions;

    httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode()
      }),
      observe: 'body',
      responseType: 'blob'
    };

    return this.httpClient.get(environment.apiUrl + url, httpOptions).pipe(
      map((data: any) =>
        data || data.data),
      catchError(error =>
      {
        if (!!error.status && error.status === 401)
        {
          console.log("Redirect to login");
          localStorage.removeItem(this.authLocalStorageToken);

          this.auth.logout();
          document.location.reload();

          return NEVER;      // <-- never emit after the redirect
        }
        else
        {
          if (!!error.status && error.status == 403)
          {
            this.helperFunctionsService.displayNotificationForbiddenAlert();
          }
        }

        return throwError(error);  // <-- pass on the error if it isn't 401 Unauthorized
      }));
  }

  //** GET */
  public fetch(url): Observable<any>
  {
    const auth = this.getAuthFromLocalStorage();

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode()
      }),
    };

    return this.httpClient.get(environment.apiUrl + url, httpOptions).pipe(
      map((data: any) =>
        data || data.data),
      catchError(error =>
      {
        if (!!error.status && error.status === 401)
        {
          console.log("Redirect to login");
          localStorage.removeItem(this.authLocalStorageToken);

          this.auth.logout();
          document.location.reload();

          return NEVER;      // <-- never emit after the redirect
        }
        else
        {
          if (!!error.status && error.status == 403)
          {
            this.helperFunctionsService.displayNotificationForbiddenAlert();
          }
        }

        return throwError(error);  // <-- pass on the error if it isn't 401 Unauthorized
      }));
  }

  //** POST */
  public postFormData(url, body): Observable<any>
  {
    const auth = this.getAuthFromLocalStorage();

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode(),
        'Accept': 'application/json',
      }),
    };

    return this.httpClient.post(environment.apiUrl + url, body, httpOptions)
      .pipe(
        map((data: any) => (data || data.data)),
        catchError(error =>
        {
          if (!!error.status && error.status === 401)
          {
            console.log("Redirect to login");

            localStorage.removeItem(this.authLocalStorageToken);

            this.auth.logout();
            document.location.reload();

            return NEVER;      // <-- never emit after the redirect
          }
          else
          {
            if (!!error.status && error.status == 403)
            {
              this.helperFunctionsService.displayNotificationForbiddenAlert();
            }
          }
          return throwError(error);  // <-- pass on the error if it isn't 401 Unauthorized
        }));
  }

  //** POST */
  public postJson(url, body): Observable<any>
  {
    const auth = this.getAuthFromLocalStorage();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode(),
      }),
    };

    return this.httpClient.post(environment.apiUrl + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => (data || data.data)),
        catchError(error =>
        {
          if (!!error.status && error.status === 401)
          {
            console.log("Redirect to login");

            localStorage.removeItem(this.authLocalStorageToken);

            this.auth.logout();
            document.location.reload();

            return NEVER;      // <-- never emit after the redirect
          }
          else
          {
            if (!!error.status && error.status == 403)
            {
              this.helperFunctionsService.displayNotificationForbiddenAlert();
            }
          }

          return throwError(error);  // <-- pass on the error if it isn't 401 Unauthorized
        }));
  }

  //** PUT */
  public putJson(url, body): Observable<any>
  {
    const auth = this.getAuthFromLocalStorage();

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode(),
      }),
    };

    return this.httpClient
      .put(environment.apiUrl + url, body.toString(), httpOptions)
      .pipe(
        map((data: any) => data || data.data),
        catchError((error) =>
        {
          if (!!error.status && error.status === 401)
          {
            console.log("Redirect to login");

            localStorage.removeItem(this.authLocalStorageToken);

            this.auth.logout();
            document.location.reload();

            return NEVER; // <-- never emit after the redirect
          } else
          {
            if (!!error.status && error.status == 403)
            {
              this.helperFunctionsService.displayNotificationForbiddenAlert();
            }
          }

          return throwError(error); // <-- pass on the error if it isn't 401 Unauthorized
        })
      );
  }

  //** DELETE */
  public deleteJson(url, body): Observable<any>
  {
    const auth = this.getAuthFromLocalStorage();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ auth.authToken }`,
        "Accept-Language": this.getLanguageCode(),
      }), body: body
    };

    return this.httpClient.delete(environment.apiUrl + url, httpOptions)
      .pipe(
        map((data: any) => (data.data || data)),
        catchError(error =>
        {
          if (!!error.status && error.status === 401)
          {
            console.log("Redirect to login");

            localStorage.removeItem(this.authLocalStorageToken);

            this.auth.logout();
            document.location.reload();

            return NEVER;      // <-- never emit after the redirect
          }
          else
          {
            if (!!error.status && error.status == 403)
            {
              this.helperFunctionsService.displayNotificationForbiddenAlert();
            }
          }

          return throwError(error);  // <-- pass on the error if it isn't 401 Unauthorized
        }));
  }

  public getAspNetDataStore(key: string, method: string, endpoint: string, body: any)
  {
    const auth = this.getAuthFromLocalStorage();

    const apiController = endpoint.split('/')[0];

    return AspNetData.createStore({
      key: key,
      loadMethod: method,
      loadUrl: body != null ? `${ environment.apiUrl }/${ endpoint }?customFilters=` + JSON.stringify(body) : `${ environment.apiUrl }/${ endpoint }`,
      deleteUrl: `${ environment.apiUrl }/` + apiController + `/devExtremeDummyUrl`,
      updateUrl: `${ environment.apiUrl }/` + apiController + `/devExtremeDummyUrl`,
      onBeforeSend: (_, httpOptions) =>
      {
        httpOptions.headers = {
          'Access-Control-Allow-Origin': '*', // Not recommended for production, better to set specific origins
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${ auth.authToken }`,
          "Accept-Language": this.getLanguageCode(),
        }
      },
      onAjaxError: (error =>
      {
        if (error && error.xhr && error.xhr.status && error.xhr.status == 401)
        {
          console.log("Redirect to login");

          localStorage.removeItem(this.authLocalStorageToken);

          this.auth.logout();
          document.location.reload();

          return NEVER;      // <-- never emit after the redirect
        }
        else
        {
          if (error && error.xhr && error.xhr.status && error.xhr.status == 403)
          {
            this.helperFunctionsService.displayNotificationForbiddenAlert();
          }
        }

        return throwError(error);  // <-- pass on the error if it isn't 401 Unauthorized
      })
    });
  }

  public deleteLayout(dataGridId: string, componentName: string, isCompanyLayout: boolean, clientId: number = null): Observable<any>
  {
    let body = {
      "ComponentName": componentName,
      "DataGridId": dataGridId,
      "IsCompanyLayout": isCompanyLayout,
      "ClientId": clientId
    }

    return this.postJson('/user/layout/delete', JSON.stringify(body));
  }

  public addOrEditLayout(dataGridId: string, componentName: string, layout: string, isCompanyLayout: boolean, clientId: number = null): Observable<any>
  {
    let body = {
      "ComponentName": componentName,
      "DataGridId": dataGridId,
      "Layout": layout,
      "IsCompanyLayout": isCompanyLayout,
      "ClientId": clientId
    }

    return this.postJson('/user/layouts', JSON.stringify(body));
  }
}