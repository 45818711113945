import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ApiService } from '../../_services/api.service';
import { catchError, finalize } from 'rxjs/operators';
import { User } from '../../_models/systemSecurity/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ApiUsersService
{
  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
  ) { }

  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  public get errorMessage$()
  {
    return this._errorMessage.asObservable();
  }

  get subscriptions()
  {
    return this._subscriptions;
  }

  public fetch()
  {
    return this.apiService.fetch("/administration/users").pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GET ', err);
        return null;
      })
    );
  }

  public get(searchParameters: any): Observable<any>
  {
    return this.apiService.postJson("/administration/users", JSON.stringify(searchParameters ? searchParameters : { "search": {}, "sort": {} })).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GET ', err);
        return null;
      })
    );
  }

  public getById(id: any): Observable<any>
  {
    return this.apiService.fetch("/administration/users/" + id).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GETBYID ', err);
        return null;
      })
    );
  }

  public add(user: User): Observable<any>
  {
    return this.apiService.postJson("/administration/users", JSON.stringify(user)).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR ADD ', err);
        return null;
      })
    );
  }

  public edit(user: User): Observable<any>
  {
    return this.apiService.putJson("/administration/users", JSON.stringify(user)).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR EDIT ', err);
        return null;
      })
    );
  }

  public delete(id: number): Observable<any>
  {
    return this.apiService.deleteJson("/administration/user/" + id, '').pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR DELETE ', err);
        return null;
      })
    );
  }

  public getAspNetDataStore(key: string, method: string, endpoint: string, body: any)
  {
    return this.apiService.getAspNetDataStore(key, method, endpoint, body);
  }

  public getCurrentUser()
  {
    return this.apiService.fetch("/user/currentuser").pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GETBYID ', err);
        return null;
      })
    );
  }

  public getMenu()
  {
    return this.apiService.fetch("/user/getMenu").pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GETBYID ', err);
        return null;
      })
    );
  }

  public getEffectiveUserPermissions()
  {
    return this.apiService.fetch("/permissions/getEffectiveUserPermissionTypes").pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GETBYID ', err);
        return null;
      })
    );
  }

  public getUserPermissions(searchParameters: any): Observable<any>
  {
    let body;

    if (searchParameters == null)
    {
      body = { "search": {}, "sort": {} };
    }
    else
    {
      body = searchParameters;
    }

    return this.apiService.postJson("/permissions/userPermissions", JSON.stringify(body)).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR GET ', err);
        return null;
      })
    );
  }

  public addUserPermission(userPermissions: any): Observable<any>
  {
    return this.apiService.postJson("/permissions/userPermission", JSON.stringify(userPermissions)).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR ADD ', err);
        return null;
      })
    );
  }

  public deleteUserPermission(id: number): Observable<any>
  {
    return this.apiService.deleteJson("/permissions/userPermission/" + id, '').pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR DELETE ', err);
        return null;
      })
    );
  }

  public sendRandomPassword(userName: string)
  {
    return this.apiService.postJson("/administration/sendRandomPassword?userName=" + userName, JSON.stringify({}));
  }

  public changePassword(oldPass: string, newPass: string, confirmPass: string, userId: string)
  {
    return this.apiService.postJson("/administration/changePassword?oldPass=" + oldPass + '&newPass=' + newPass + '&confirmPass=' + confirmPass + '&userId=' + userId, JSON.stringify({}));
  }

  public sendEmail2WayCurrentUser()
  {
    return this.apiService.fetch("/user/sendEmail2WayCurrentUser").pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR SendEmail2WayCurrentUser ', err);
        return null;
      })
    );
  }

  public get2WayCurrentUserCode(code)
  {
    return this.apiService.fetch("/user/get2WayCurrentUserCode/" + code).pipe(
      catchError(err =>
      {
        this._errorMessage.next(err);
        console.error('ERROR Get2WayCurrentUserCode ', err);
        return null;
      })
    );
  }

  public getAppVersion()
  {
    return this.apiService.fetch("/user/getAppVersion");
  }

  public getResetPasswordTokenString(email: string): Observable<any>
  {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    return this.httpClient.post(environment.tokenUrl + "/authorization/getResetPasswordTokenString?email=" + email, null);
  }

  public resetPassword(formData: FormData)
  {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    return this.httpClient.post(environment.tokenUrl + "/authorization/resetPassword", formData);
  }
}